import { EventType } from 'src/dto/pmo/eventtype';

export enum EventPriority {
    "Low" = 1,
    "Medium" = 2,
    "High" = 3,
    "Critical" = 4,
    "Showstopper" = 5
};

export enum ragStatus {
    "Red" = 1,
    "Amber" = 2,
    "Green" = 3,
    "Gray" = 4
}

export const ZendeskTicketSeverity = [
   {id: "Low - 4", text: "Low - 4"},
   {id: "Moderate - 3", text: "Moderate - 3"},
   {id: "Severe - 2", text: "Severe - 2"},
   {id: "Critical - 1", text: "Critical - 1"}
]

export const priorityList = [
    {
        id: 0,
        label: "0 - Unknown",
        matIcon: "circle",
        color: "var(--palette-gray-background-color)",
        textColor: "#121212"
    },
    {
        id: 5,
        label: "5 - Low",
        matIcon: "keyboard_double_arrow_down",
        color: "var(--palette-blue-background-color)",
        textColor: "#fff"
    },
    {
        id: 4,
        label: "4 - Medium",
        matIcon: "horizontal_rule",
        color: "var(--palette-yellow-background-color)",
        textColor: "#121212"
    },
    {
        id: 3,
        label: "3 - High",
        matIcon: "keyboard_double_arrow_up",
        color: "var(--palette-amber-background-color)",
        textColor: "#121212"
    },
    {
        id: 2,
        label: "2 - Critical",
        matIcon: "warning_amber",
        color: "var(--palette-orange-background-color)",
        textColor: "#121212"
    },
    {
        id: 1,
        label: "1 - Showstopper",
        matIcon: "dangerous",
        color: "var(--palette-red-background-color)",
        textColor: "#121212"
    }
];

export const statusList = [
    {
        id: "red",
        label: 'Red',
        value: "#FF0000"
    },
    {
        id: "amber",
        label: 'Amber'
    },
    {
        id: "green",
        label: 'Green'
    },
    {
        id: "gray",
        label: 'Gray'
    }
];

/**
 * List of DTO names for planning events
 */
export const planningEventNames = [
    "Milestone_v1",
    "Project_v1",
    "Goal_v1",
    "CoverageGoal_v1",
    "Objective_v1",
    "CoverageObjective_v1",
    "Task_v1",
    "CoverageTask_v1",
    "Event_v1"
];

/**
 * List of eventTypes that are planning events
 */
export const planningEventTypes = [
    EventType.MILESTONE,
    EventType.PROJECT,
    EventType.GOAL,
    EventType.COVERAGE_GOAL,
    EventType.OBJECTIVE,
    EventType.COVERAGE_OBJECTIVE,
    EventType.TASK,
    EventType.COVERAGE_TASK
];

/**
 * List of Meeting event types
 */
export const calendarEventTypesDEPRICATED = [
    EventType.PRIVATE_VALUE_EVENT,
    EventType.PUBLIC_VALUE_EVENT,
    EventType.DYNATRACE_VALUE_EVENT,
    EventType.QUARTERLY_BUSINESS_REVIEW,
    EventType.VELOCITY_SERVICES_EVENT,
    EventType.ESA_SERVICES_EVENT,
    EventType.EPM_SERVICES_EVENT,
    EventType.PROJECT_MEETING,
    EventType.TRAINING_SESSION
];

// const isDTOMeeting = (eventType: EventType) => {
//     return [
//         EventType.SAAS_CLUSTER_RELEASE,
//         EventType.MANAGED_CLUSTER_RELEASE,
//         EventType.PRIVATE_VALUE_EVENT,
//         EventType.PUBLIC_VALUE_EVENT,
//         EventType.DYNATRACE_VALUE_EVENT,
//         EventType.QUARTERLY_BUSINESS_REVIEW,
//         EventType.VELOCITY_SERVICES_EVENT,
//         EventType.ESA_SERVICES_EVENT,
//         EventType.EPM_SERVICES_EVENT,
//         EventType.PROJECT_MEETING,
//         EventType.TRAINING_SESSION
//     ].includes(eventType)
// }
